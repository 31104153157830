@charset "UTF-8";
/* Image Buttons (completely seperate button style) */
/* Holds helper mixins (extends compass functionality) */
/* Holds reusable objects for global use */
/* =============================================================================
 *   Navigation
 * ========================================================================== */
/* Tiny Menu */
/* =============================================================================
 *   Box Styles
 * ========================================================================== */
/* =============================================================================
 *   Messages
 * ========================================================================== */
/* Flyout */
/* Overlay */
/* Cutline */
/* Section Titles */
/* webks: Tools > overlay */
.webks-tools-page-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9998;
  height: 100%;
  width: 100%;
  /* overriden by jquery */
  background: rgba(255, 255, 255, 0.45);
  filter: blur(5px); }

/* =============================================================================
 *   Animations
 * ========================================================================== */
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes fadeOutLeft {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

@keyframes fadeOutRight {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

/* =============================================================================
 *   Foundation Dropdown Panes
 * ========================================================================== */
.dropdown-pane {
  background-color: white;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  display: block;
  font-size: 1rem;
  padding: 15px;
  position: absolute;
  visibility: hidden;
  width: 300px;
  z-index: 10; }
  .dropdown-pane.is-open {
    visibility: visible; }
  .dropdown-pane.tiny {
    width: 100px; }
  .dropdown-pane.small {
    width: 200px; }
  .dropdown-pane.large {
    width: 400px; }

/* =============================================================================
 *   Buttons
 * ========================================================================== */
.btn,
.button,
.btn-inside a {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.05em;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  border-color: #0e4dac !important;
  background-color: #0e4dac !important;
  color: #fff !important;
  padding: 12px 12px;
  border-radius: 5px;
  line-height: 18px;
  font-size: 16px; }
  .btn:hover,
  .button:hover,
  .btn-inside a:hover {
    border-color: #1058c4 !important;
    background-color: #1058c4 !important;
    color: #fff !important; }
  .btn:active, .btn:focus,
  .button:active,
  .button:focus,
  .btn-inside a:active,
  .btn-inside a:focus {
    border-color: #0c4294 !important;
    background-color: #0c4294 !important;
    color: #fff !important; }
  .btn:hover,
  .button:hover,
  .btn-inside a:hover {
    text-decoration: none; }

/* -- COLORS */
/* -- -- Green */
.btn--green,
.btn-inside--green a {
  background-color: #bcc826 !important;
  border-color: #bcc826 !important;
  color: white !important; }
  .btn--green:hover,
  .btn-inside--green a:hover {
    background-color: #c8d528 !important;
    border-color: #c8d528 !important;
    color: white !important; }
  .btn--green:active, .btn--green:focus,
  .btn-inside--green a:active,
  .btn-inside--green a:focus {
    background-color: #b0bb24 !important;
    border-color: #b0bb24 !important;
    color: #6b7216 !important; }

/* -- -- Red */
.btn--red,
.btn-inside--red a {
  background-color: #d02b2b !important;
  border-color: #d02b2b !important;
  text-shadow: 0 1px 1px #dd5b5b;
  color: white !important; }
  .btn--red:hover,
  .btn-inside--red a:hover {
    background-color: #d53535 !important;
    border-color: #d53535 !important;
    color: white !important; }
  .btn--red:active, .btn--red:focus,
  .btn-inside--red a:active,
  .btn-inside--red a:focus {
    background-color: #c32828 !important;
    border-color: #c32828 !important;
    color: #7b1a1a !important; }

/* -- -- Ghost */
.btn--ghost,
.btn-inside--ghost a {
  background-color: transparent !important;
  border-color: #d0d0d0 !important;
  color: #484848 !important; }
  .btn--ghost:hover,
  .btn-inside--ghost a:hover {
    background-color: transparent !important;
    border-color: #e4e4e4 !important;
    color: #0e4dac !important; }
  .btn--ghost:active, .btn--ghost:focus,
  .btn-inside--ghost a:active,
  .btn-inside--ghost a:focus {
    background-color: transparent !important;
    border-color: #c3c3c3 !important;
    color: #484848 !important; }

/* -- -- Disabled */
.btn--disabled,
.btn-inside--disabled a,
button:disabled,
input[type="submit"]:disabled {
  background-color: #e3e3e3 !important;
  border-color: #959ba4 !important;
  color: #6d747f !important; }
  .btn--disabled:hover,
  .btn-inside--disabled a:hover,
  button:disabled:hover,
  input[type="submit"]:disabled:hover {
    background-color: #dedede !important;
    border-color: #959ba4 !important;
    color: #6d747f !important; }
  .btn--disabled:active, .btn--disabled:focus,
  .btn-inside--disabled a:active,
  .btn-inside--disabled a:focus,
  button:disabled:active,
  button:disabled:focus,
  input[type="submit"]:disabled:active,
  input[type="submit"]:disabled:focus {
    background-color: #c32828 !important;
    border-color: #c32828 !important;
    color: #7b1a1a !important; }

/* -- -- Naked (no style, just an link with touch friendly size) */
.btn--naked,
.btn-inside--naked a {
  border-color: transparent !important;
  background-color: transparent !important;
  color: #484848 !important;
  text-decoration: underline;
  padding-left: 0 !important;
  padding-right: 0 !important; }
  .btn--naked:hover,
  .btn-inside--naked a:hover {
    border-color: transparent !important;
    background-color: transparent !important;
    color: #0e4dac !important; }
  .btn--naked:active, .btn--naked:focus,
  .btn-inside--naked a:active,
  .btn-inside--naked a:focus {
    border-color: transparent !important;
    background-color: transparent !important;
    color: #0e4dac !important; }

/* -- Sizes */
.btn--xs {
  padding: 6px 6px;
  border-radius: 5px;
  line-height: 1em;
  font-size: 12px; }

.btn--xl {
  padding: 15px 15px;
  border-radius: 5px;
  line-height: 18px;
  font-size: 16px; }

.btn--xxl {
  padding: 21px 15px;
  border-radius: 5px;
  line-height: 18px;
  font-size: 16px; }

.btn--xxxl {
  padding: 27px 27px;
  border-radius: 5px;
  line-height: 18px;
  font-size: 16px; }

/* Circle Buttons */
.btn--circle {
  text-indent: -9000px;
  text-align: left;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  line-height: 42px;
  padding: 0 !important; }
  .btn--circle::before {
    float: left;
    text-indent: 0;
    font-size: 18px;
    vertical-align: middle;
    width: 42px;
    line-height: 42px !important;
    text-align: center;
    margin: 0 0 0 -1px !important; }
  .btn--circle.btn--xs {
    height: 24px;
    width: 24px;
    line-height: 24px; }
    .btn--circle.btn--xs::before {
      font-size: 12px;
      height: 24px;
      width: 24px;
      line-height: 24px !important; }
  .btn--circle.btn--xl {
    height: 62px;
    width: 62px;
    line-height: 62px; }
    .btn--circle.btn--xl::before {
      font-size: 32px;
      height: 62px;
      width: 62px;
      line-height: 62px !important; }
  .btn--circle.btn--xxl {
    height: 88px;
    width: 88px;
    line-height: 88px; }
    .btn--circle.btn--xxl::before {
      font-size: 46px;
      height: 88px;
      width: 88px;
      line-height: 88px !important; }
  .btn--circle.btn--xxxl {
    height: 110px;
    width: 110px;
    line-height: 110px; }
    .btn--circle.btn--xxxl::before {
      font-size: 56px;
      height: 110px;
      width: 110px;
      line-height: 110px !important; }

/* -- Full Width Buttons */
.btn--block,
.btn-inside--blocked a {
  display: block; }

/* -- Icons inside buttons - for example <a class="btn ico-zoom-in">Buttontext</a> */
.btn::before {
  display: inline-block;
  font-family: "iconfont";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  margin-right: 5px; }

/* =============================================================================
 *   Icons
 * ========================================================================== */
/* Iconfont */
@font-face {
  font-family: 'iconfont';
  src: url("../fonts/iconfont/iconfont.eot?-cbfawx");
  src: url("../fonts/iconfont/iconfont.eot?#iefix-cbfawx") format("embedded-opentype"), url("../fonts/iconfont/iconfont.woff2?-cbfawx") format("woff2"), url("../fonts/iconfont/iconfont.woff?-cbfawx") format("woff"), url("../fonts/iconfont/iconfont.ttf?-cbfawx") format("truetype"), url("../fonts/iconfont/iconfont.svg?-cbfawx#iconfont") format("svg");
  font-weight: normal;
  font-style: normal; }

.ico::before,
.ico-inline::before {
  font-family: "iconfont";
  font-weight: normal !important;
  font-style: normal !important;
  margin-right: 4px; }

.ico-seperate {
  text-indent: -9000px;
  text-align: left;
  display: inline-block;
  float: left;
  margin-right: 4px;
  font-size: 0;
  width: 16px; }
  .ico-seperate::before {
    text-indent: 0;
    font-family: "iconfont";
    float: right;
    text-align: center;
    width: 100%;
    font-size: 18px; }
  .ico-seperate::after {
    clear: both;
    content: " ";
    display: table;
    visibility: hidden; }

.menu > li > a.ico-inline::before {
  font-size: 16px;
  font-weight: normal !important;
  vertical-align: middle; }

/* -- Verfuegbare Icons (see _objects.scss) */
.ico-search::before {
  content: ""; }

.ico-check::before {
  content: ""; }

.ico-circle-check::before {
  content: ""; }

.ico-remove::before {
  content: ""; }

.ico-circle-remove::before {
  content: ""; }

.ico-plus::before {
  content: ""; }

.ico-circle-plus::before {
  content: ""; }

.ico-minus::before {
  content: ""; }

.ico-circle-minus::before {
  content: ""; }

.ico-exclamation::before {
  content: ""; }

.ico-circle-exclamation::before {
  content: ""; }

.ico-info::before {
  content: ""; }

.ico-circle-info::before {
  content: ""; }

.ico-menu::before {
  content: ""; }

.ico-print::before {
  content: ""; }

.ico-phone::before {
  content: ""; }

.ico-mail::before {
  content: ""; }

.ico-form::before {
  content: ""; }

.ico-user::before {
  content: ""; }

.ico-user-group::before {
  content: ""; }

.ico-user-add::before {
  content: ""; }

.ico-cart::before {
  content: ""; }

.ico-arrow-top::before {
  content: ""; }

.ico-arrow-right::before {
  content: ""; }

.ico-arrow-bottom::before {
  content: ""; }

.ico-arrow-left::before {
  content: ""; }

.ico-arrow-thin-top::before {
  content: ""; }

.ico-arrow-thin-right::before {
  content: ""; }

.ico-arrow-thin-bottom::before {
  content: ""; }

.ico-arrow-thin-left::before {
  content: ""; }

.ico-circle-arrow-top::before {
  content: ""; }

.ico-circle-arrow-right::before {
  content: ""; }

.ico-circle-arrow-bottom::before {
  content: ""; }

.ico-circle-arrow-left::before {
  content: ""; }

.ico-double-arrow-top::before {
  content: ""; }

.ico-double-arrow-down::before {
  content: ""; }

.ico-locked::before {
  content: ""; }

.ico-key::before {
  content: ""; }

.ico-reset::before {
  content: ""; }

.ico-home::before {
  content: ""; }

.ico-clock::before {
  content: ""; }

.ico-calendar::before {
  content: ""; }

.ico-twitter::before {
  content: ""; }

.ico-facebook::before {
  content: ""; }

.ico-gplus::before {
  content: ""; }

.ico-youtube::before {
  content: ""; }

.ico-ticket::before {
  content: ""; }

.ico-theater::before {
  content: ""; }

.ico-store::before {
  content: ""; }

.ico-map::before {
  content: ""; }

.ico-alarm::before {
  content: ""; }

.ico-briefcase::before {
  content: ""; }

.ico-road::before {
  content: ""; }

.ico-furniture::before {
  content: ""; }

.ico-mortarboard::before {
  content: ""; }

.ico-aidkit::before {
  content: ""; }

.ico-city::before {
  content: ""; }

.ico-soccer::before {
  content: ""; }

.ico-kids::before {
  content: ""; }

.ico-images::before {
  content: ""; }

.ico-meal::before {
  content: ""; }

.ico-misc::before {
  content: ""; }

.ico-wir-in-luebbecke::before {
  content: ""; }

/* -- Icon Sizes */
.ico--xs::before {
  font-size: 12px; }

.ico--xs.ico-seperate {
  width: 12px;
  height: 12px;
  line-height: 12px; }

.ico--normal::before {
  font-size: 18px; }

.ico--normal.ico-seperate {
  width: 18px;
  height: 18px;
  line-height: 18px; }

.ico--xl::before {
  font-size: 32px; }

.ico--xl.ico-seperate {
  width: 32px;
  height: 32px;
  line-height: 32px; }

.ico--xxl::before {
  font-size: 46px; }

.ico--xxl.ico-seperate {
  width: 46px;
  height: 46px;
  line-height: 46px; }

.ico--xxxl::before {
  font-size: 56px; }

.ico--xxxl.ico-seperate {
  width: 56px;
  height: 56px;
  line-height: 56px; }

/* Term ID based Icons */
.ico-tid--13 {
  background-color: #1d57c0 !important; }
  .ico-tid--13::before {
    content: ""; }

.ico-tid--14 {
  background-color: #b812db !important; }
  .ico-tid--14::before {
    content: ""; }

.ico-tid--15::before {
  content: ""; }

.ico-tid--18 {
  background-color: #127fdb !important; }
  .ico-tid--18::before {
    content: ""; }

.ico-tid--22 {
  background-color: #db7512 !important; }
  .ico-tid--22::before {
    content: ""; }

.ico-tid--23 {
  background-color: #c01d1d !important; }
  .ico-tid--23::before {
    content: ""; }

.ico-tid--27 {
  background-color: #8cc01d !important; }
  .ico-tid--27::before {
    content: ""; }

.ico-tid--30 {
  background-color: #f1cd08 !important; }
  .ico-tid--30::before {
    content: ""; }

.poi-typ-ico {
  font-family: "iconfont"; }
  .poi-typ-ico::before {
    font-family: inherit;
    display: block;
    line-height: 33px;
    font-size: 15px; }
  .poi-typ-ico--mortarboard::before {
    content: ""; }
  .poi-typ-ico--meal::before {
    content: ""; }
  .poi-typ-ico--soccer::before {
    content: ""; }
  .poi-typ-ico--aidkit::before {
    content: ""; }
  .poi-typ-ico--city::before {
    content: ""; }
  .poi-typ-ico--user-group::before {
    content: ""; }
  .poi-typ-ico--furniture::before {
    content: ""; }
  .poi-typ-ico--misc::before {
    content: ""; }

.ico-reset {
  font-family: "iconfont"; }
  .ico-reset::before {
    font-family: inherit;
    line-height: 21px;
    vertical-align: top; }

/* =============================================================================
 *   Box Styles
 * ========================================================================== */
/* Default Box Style */
.box-style {
  border-width: 1px;
  border-style: solid;
  padding: 25px 43px;
  border-color: #ebebeb;
  border-radius: 0;
  background-color: white;
  overflow: hidden;
  *overflow: visible;
  *zoom: 1; }

.box-style--info {
  position: relative;
  font-size: 12px;
  padding-left: 60px;
  line-height: 1.1em;
  border-color: #9adcea;
  text-shadow: 1px 1px 1px #9adcea;
  background-color: #bce8f1;
  color: #31708f;
  cursor: help; }
  .box-style--info a {
    color: #31708f; }
  .box-style--info::before {
    content: "";
    text-shadow: none;
    font-family: "iconfont";
    background-color: white;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: white;
    text-align: center;
    line-height: 30px;
    vertical-align: middle;
    position: absolute;
    left: 15px;
    top: 13px;
    font-size: 22px; }

/* Expandable Blocks */
.webks-expandable-container {
  position: relative; }
  .webks-expandable-container.default-hidden:not(.webks-expandable-container--expanded) .block-content,
  .webks-expandable-container.default-hidden:not(.webks-expandable-container--expanded) .webks-expandable-container__content {
    display: none; }
  .webks-expandable-container--expanded .webks-expandable-container__content {
    display: block; }
  .webks-expandable-container__trigger {
    cursor: pointer; }
  .webks-expandable-container__content:hover {
    z-index: 1000 !important; }

/* -- Hide them if js isnt loaded / disabled */
html:not(.js) .block--expandable {
  display: none; }

/* =============================================================================
 *   Slideshows
 * ========================================================================== */
.slick {
  overflow: hidden; }
  .slick img {
    max-width: none;
    width: 100%; }
  .slick .slick__arrow {
    height: 70px;
    margin: -35px 0 0 !important; }
  .slick .slick-arrow {
    top: 0;
    text-indent: -9000px;
    height: 70px;
    margin: 0 !important;
    padding: 0;
    width: 35px;
    border: 0 none;
    color: #484848 !important;
    background: white !important;
    animation-duration: 500ms;
    animation-fill-mode: both; }
    .slick .slick-arrow:hover, .slick .slick-arrow:focus {
      color: #0e4dac !important;
      background: #fff !important; }
    .slick .slick-arrow::before {
      font-family: "iconfont";
      text-indent: 0;
      position: absolute;
      top: 0 !important;
      left: 0;
      color: currentColor;
      line-height: 70px;
      vertical-align: middle;
      width: 35px !important;
      height: 70px !important;
      opacity: 1;
      text-align: center;
      font-size: 18px; }
    .slick .slick-arrow:hover {
      color: #d02b2b;
      background: #fff; }
    .slick .slick-arrow.slick-prev {
      border-radius: 0 70px 70px 0;
      animation-name: fadeOutLeft; }
      .slick .slick-arrow.slick-prev::before {
        content: ""; }
    .slick .slick-arrow.slick-next {
      border-radius: 70px 0 0 70px;
      animation-name: fadeOutRight; }
      .slick .slick-arrow.slick-next::before {
        content: ""; }
  .slick:focus-within .slick-arrow.slick-prev, .slick:hover .slick-arrow.slick-prev {
    animation-name: fadeInLeft; }
  .slick:focus-within .slick-arrow.slick-next, .slick:hover .slick-arrow.slick-next {
    animation-name: fadeInRight; }

/* Slider Overlays */
.slide-overlay {
  color: white;
  overflow: hidden;
  max-width: 100%;
  flex-shrink: 1;
  background: linear-gradient(to bottom, #959ba4 0%, #a2a8b0 100%);
  animation-duration: 650ms;
  animation-fill-mode: both; }
  @media only screen and (min-width: 768px) {
    .slide-overlay {
      position: absolute;
      left: 0;
      width: 100%; } }
  .slide-overlay--highlight-sec {
    background: linear-gradient(to bottom, #d02b2b 0%, #d53535 100%); }
  .slide-overlay--bottom {
    bottom: 0; }
  .slide-overlay__contents {
    padding: 15px;
    max-width: 100%;
    flex: 1;
    overflow: hidden; }
  .slide-overlay__title {
    margin: 0;
    color: white;
    font-family: "Open Sans", Verdana, Geneva, "DejaVu Sans", Arial, Helvetica, sans-serif;
    font-size: 24px;
    line-height: 1.2em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    @media only screen and (min-width: 992px) {
      .slide-overlay__title {
        font-size: 35px; } }
  .slide-overlay__bottom-line {
    white-space: nowrap;
    display: flex;
    justify-content: space-between; }
  .slide-overlay__description {
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    margin: 0; }
    @media only screen and (min-width: 768px) {
      .slide-overlay__description {
        float: left; } }
  .slide-overlay__read-on {
    float: right;
    margin-left: 15px; }
  .slide-overlay a {
    color: white; }
  .slide-overlay--event {
    display: flex; }

.slick__slide.slick-active .slide-overlay,
.owl-item.active .slide-overlay {
  animation-name: fadeInUp; }

/* =============================================================================
 *   Media Objects
 * ========================================================================== */
.media-object {
  overflow: hidden;
  *overflow: visible;
  *zoom: 1;
  margin-left: -7.5px;
  margin-right: -7.5px;
  display: flex; }
  .media-object__image {
    position: relative;
    float: left;
    min-height: 1px;
    padding-left: 7.5px;
    padding-right: 7.5px;
    max-width: 35%;
    text-align: center;
    line-height: 0; }
    .media-object__image a {
      display: block; }
    @media (min-width: 992px) {
      .media-object__image {
        align-self: stretch; }
        .media-object__image *:not(img) {
          display: block;
          height: 100%; } }
  .media-object__content {
    position: relative;
    float: left;
    min-height: 1px;
    padding-left: 7.5px;
    padding-right: 7.5px;
    width: 75%;
    flex: 1; }
    .media-object__content > header {
      margin-bottom: 1rem; }
  .media-object__title {
    margin-top: 0; }
  .media-object:hover .media-object__title {
    color: #d02b2b; }
    .media-object:hover .media-object__title a {
      color: #d02b2b; }
  .media-object--date {
    margin-left: 0;
    margin-right: 0;
    border: 1px solid #ebebeb;
    padding-right: 15px; }
    @media (max-width: 1139px) {
      .media-object--date {
        flex-wrap: wrap; } }
    .media-object--date .media-object__image {
      padding-left: 0;
      min-width: 127.5px;
      max-width: 127.5px; }
      .media-object--date .media-object__image .image-caption {
        display: none !important; }
    .media-object--date .media-object__content {
      width: auto;
      align-self: center; }
      .media-object--date .media-object__content .node__title {
        font-size: 18px;
        line-height: 1em; }
    .media-object--date .media-object__links {
      float: right;
      margin-left: auto;
      align-self: center;
      display: flex;
      align-items: center; }
      .media-object--date .media-object__links a {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 8px; }

/* =============================================================================
 *   Misc Objects
 * ========================================================================== */
/* Date */
.date-rect {
  display: flex;
  flex-shrink: 1;
  align-items: stretch;
  align-content: stretch;
  justify-content: center; }
  @media only screen and (min-width: 768px) {
    .date-rect {
      min-width: 150px;
      flex-shrink: 0; } }
  .date-rect > .inner {
    display: flex;
    align-items: center;
    align-content: stretch;
    justify-content: center;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    background: #0e4dac;
    color: #fff;
    font-family: "Crimson Text", Georgia, serif; }
  .date-rect--date-range .date-wrapper {
    display: inline-block; }

.slick .date-rect {
  min-width: 0;
  flex-shrink: 1; }

.date-wrapper {
  text-align: center;
  line-height: 1.1em;
  padding: 3px; }
  .date-wrapper .dayname {
    font-size: 30px;
    padding-bottom: 7px; }
    .date-wrapper .dayname + .date-short {
      padding-top: 1px;
      border-top: 2px solid #fff; }
  .date-wrapper .date-short {
    display: inline-block; }

/* Cutlines */
.cutline-top {
  box-shadow: 0 -1px 0 #ebebeb;
  padding-top: 15px;
  margin-top: 15px; }

.cutline-bottom {
  box-shadow: 0 1px 0 #ebebeb;
  padding-bottom: 15px;
  margin-bottom: 15px; }

.field:not(.field-name-body).clearfix::after {
  display: none; }

/* Tags (Tagstyle) */
.tagstyle,
.tagstyle ul,
.field-name-field-tags {
  margin: 0;
  padding: 0; }

.tagstyle__tag,
.tagstyle li,
.tagstyle ul li,
.tagstyle .field-item,
.field-name-field-tags .field-item {
  list-style: none;
  margin: 0 0.25em 0.25em 0;
  padding: 0;
  display: inline-block; }

.tagstyle__tag a,
.tagstyle li a,
.tagstyle ul li a,
.tagstyle .field-item a,
.field-name-field-tags .field-item a {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.05em;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  background-color: transparent !important;
  border-color: #d0d0d0 !important;
  color: #484848 !important;
  padding: 6px 6px;
  border-radius: 5px;
  line-height: 1em;
  font-size: 12px; }
  .tagstyle__tag a:hover,
  .tagstyle li a:hover,
  .tagstyle ul li a:hover,
  .tagstyle .field-item a:hover,
  .field-name-field-tags .field-item a:hover {
    background-color: transparent !important;
    border-color: #e4e4e4 !important;
    color: #0e4dac !important; }
  .tagstyle__tag a:active, .tagstyle__tag a:focus,
  .tagstyle li a:active,
  .tagstyle li a:focus,
  .tagstyle ul li a:active,
  .tagstyle ul li a:focus,
  .tagstyle .field-item a:active,
  .tagstyle .field-item a:focus,
  .field-name-field-tags .field-item a:active,
  .field-name-field-tags .field-item a:focus {
    background-color: transparent !important;
    border-color: #c3c3c3 !important;
    color: #484848 !important; }
  .tagstyle__tag a:hover,
  .tagstyle li a:hover,
  .tagstyle ul li a:hover,
  .tagstyle .field-item a:hover,
  .field-name-field-tags .field-item a:hover {
    text-decoration: none; }
  .tagstyle__tag a .ico-seperate::before,
  .tagstyle li a .ico-seperate::before,
  .tagstyle ul li a .ico-seperate::before,
  .tagstyle .field-item a .ico-seperate::before,
  .field-name-field-tags .field-item a .ico-seperate::before {
    font-size: 12px;
    line-height: 1em; }

/* Subline & Submitted */
.field-name-field-subline,
.subline,
.submitted {
  color: #959595;
  font-size: 12px;
  margin-bottom: 0.5em;
  line-height: 1.15em; }

.has-subline {
  margin-bottom: .15em; }

/* Lists */
.checklist {
  list-style: none outside none;
  padding-left: 5px; }
  .checklist li {
    margin-bottom: 0.75em;
    padding-left: 33px;
    position: relative; }
    .checklist li:before {
      border: 2px solid #DFE3E4;
      border-radius: 100% 100% 100% 100%;
      color: #4db848;
      content: "";
      font-family: "iconfont";
      font-size: 18px;
      left: 0;
      line-height: 18px;
      padding: 2px;
      position: absolute;
      top: -2px; }

/* Responsive embed videos */
iframe {
  max-width: 100%;
  height: auto; }

.embed-responsive,
.responsive-iframe-wrapper {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video,
  .responsive-iframe-wrapper .embed-responsive-item,
  .responsive-iframe-wrapper iframe,
  .responsive-iframe-wrapper embed,
  .responsive-iframe-wrapper object,
  .responsive-iframe-wrapper video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0; }

.responsive-iframe-wrapper,
.embed-responsive-16by9 {
  padding-bottom: 56.25%; }

.embed-responsive-4by3 {
  padding-bottom: 75%; }

.leaflet-pane {
  z-index: 1; }

.pane--custom-directions-map .pane-title {
  margin-top: 0;
  font-size: 16px; }

.custom-directions-map {
  overflow: hidden;
  margin-bottom: 25px; }
  .custom-directions-map__form label {
    display: block; }

.leaflet-interactive .poi-typ-ico {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -17px;
  margin-top: -53px;
  width: 33px;
  height: 53px;
  min-width: 0;
  color: #fff; }
  .leaflet-interactive .poi-typ-ico:hover {
    text-shadow: 0 0 2px rgba(255, 255, 255, 0.5); }

.leaflet-popup-content-wrapper {
  border-radius: 5px; }

.leaflet-popup-content {
  font-weight: 400;
  font-family: "Open Sans", Verdana, Geneva, "DejaVu Sans", Arial, Helvetica, sans-serif; }
  .leaflet-popup-content h2,
  .leaflet-popup-content h3,
  .leaflet-popup-content h4 {
    margin-bottom: .25rem; }
  .leaflet-popup-content p {
    margin: 0 0 .5em; }

.leaflet-tagged-marker.synced-content-hover > img {
  width: 33px !important;
  height: 53px !important;
  margin-left: -17px !important;
  margin-top: -53px !important; }

.leaflet-tagged-marker.synced-content-hover > i {
  top: 0 !important; }

.leaflet-view--scroll-protected {
  position: relative; }

.leaflet-scroll-protection-overlay {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  padding: 10px;
  background: rgba(0, 0, 0, 0.55);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-shadow: 1px 1px 1px #000; }

/* =============================================================================
 *   Tooltips (via Popper.js > https://popper.js.org/documentation.html)
 * ========================================================================== */
.popper {
  background: #0e4dac;
  color: #fff;
  padding: 15px;
  width: 150px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 5px 3px -2px rgba(0, 0, 0, 0.4); }
  .popper[x-placement^="top"] {
    margin-bottom: 5px; }
    .popper[x-placement^="top"] .popper__arrow {
      border-width: 5px 5px 0 5px;
      border-color: #0e4dac transparent transparent transparent;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0; }
  .popper[x-placement^="bottom"] {
    margin-top: 5px; }
    .popper[x-placement^="bottom"] .popper__arrow {
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent #0e4dac transparent;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0; }
  .popper[x-placement^="right"] {
    margin-left: 5px; }
    .popper[x-placement^="right"] .popper__arrow {
      border-width: 5px 5px 5px 0;
      border-color: transparent #0e4dac transparent transparent;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0; }
  .popper[x-placement^="left"] {
    margin-right: 5px; }
    .popper[x-placement^="left"] .popper__arrow {
      border-width: 5px 0 5px 5px;
      border-color: transparent transparent transparent #0e4dac;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0; }
  .popper a {
    color: #fff; }
  .popper .popper__arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px; }

/* =============================================================================
 *   jQuery.UI
 * ========================================================================== */
/* =============================================================================
 *   Messages
 * ========================================================================== */
/* Left and right margin are set by the global gutter width */
.message,
div.messages {
  padding: 15px 15px 15px 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 5px;
  position: relative;
  color: #484848;
  background-color: white;
  border-color: #ebebeb; }
  .message::before,
  div.messages::before {
    font-family: "iconfont";
    position: absolute;
    left: 15px;
    top: 14px;
    font-size: 25px;
    line-height: normal; }
  .message ul,
  div.messages ul {
    margin-top: 0;
    margin-bottom: 0; }
  .message a,
  div.messages a {
    color: #484848; }
  .message::before,
  div.messages::before {
    content: ""; }

/* Message Types */
.message--info,
div.info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1; }
  .message--info a,
  div.info a {
    color: #31708f; }
  .message--info::before,
  div.info::before {
    content: ""; }

.message--success,
div.status {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6; }
  .message--success a,
  div.status a {
    color: #3c763d; }
  .message--success::before,
  div.status::before {
    content: ""; }

.message--warning,
div.warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc; }
  .message--warning a,
  div.warning a {
    color: #8a6d3b; }
  .message--warning::before,
  div.warning::before {
    content: ""; }

.message--error,
div.error {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1; }
  .message--error a,
  div.error a {
    color: #a94442; }
  .message--error::before,
  div.error::before {
    content: ""; }

/* -- Other warning & error elements */
tr.warning {
  color: #8a6d3b; }

tr.error {
  color: #a94442; }

/* Form Errors */
input[type="text"].error,
.form-text.error,
textarea.error,
.error {
  border-color: #ebccd1; }

/*
 * Unpublished nodes
 */
.node-unpublished p.unpublished,
.comment-unpublished p.unpublished {
  color: pink;
  color: rgba(239, 170, 170, 0.4);
  font-family: Impact,"Arial Narrow",Helvetica,sans-serif;
  font-size: 75px;
  font-weight: bold;
  height: 0;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  overflow: visible;
  text-align: center;
  text-transform: uppercase;
  word-wrap: break-word; }
