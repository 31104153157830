// _base.scss imports _custom.scss (variables and mixins) and other partials.
@import "base";

// Stores reusable objects like buttons, icons, that are used directly as class inside html (Source: _objects.scss)

/* webks: Tools > overlay */
.webks-tools-page-overlay{
  @include overlay;
}

/* =============================================================================
 *   Animations
 * ========================================================================== */

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}


@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

/* =============================================================================
 *   Foundation Dropdown Panes
 * ========================================================================== */

.dropdown-pane {
  background-color: white;
  border: 1px solid $grey_light;
  border-radius: $border_radius;
  display: block;
  font-size: 1rem;
  padding: $thin_grid;
  position: absolute;
  visibility: hidden;
  width: 300px;
  z-index: 10;
  &.is-open {
    visibility: visible;
  }
  &.tiny { width: 100px; }
  &.small { width: 200px; }
  &.large { width: 400px; }
}

/* =============================================================================
 *   Buttons
 * ========================================================================== */

.btn,
.button, // This class is used by some modules
.btn-inside a{
  @include btn;
}

/* -- COLORS */
/* -- -- Green */
.btn--green,
.btn-inside--green a{
  @include btn-mod('green');
}

/* -- -- Red */
.btn--red,
.btn-inside--red a{
  @include btn-mod('red');
}

/* -- -- Ghost */
.btn--ghost,
.btn-inside--ghost a{
  @include btn-mod('ghost');
}

/* -- -- Disabled */
.btn--disabled,
.btn-inside--disabled a,
button:disabled,
input[type="submit"]:disabled{
  @include btn-mod('disabled');
}

/* -- -- Naked (no style, just an link with touch friendly size) */
.btn--naked,
.btn-inside--naked a{
  @include btn-mod('naked');
}

/* -- Sizes */
.btn--xs{
  @include btn-size(xs);
}
.btn--xl{
  @include btn-size(xl);
}
.btn--xxl{
  @include btn-size(xxl);
}
.btn--xxxl{
  @include btn-size(xxxl);
}

/* Circle Buttons */
.btn--circle{
  text-indent:-9000px;
  text-align:left;
  border-radius:50%;
  height:$icon_size_normal + ($btn_default_v_space * 2);
  width:$icon_size_normal + ($btn_default_v_space * 2);
  line-height:$icon_size_normal + ($btn_default_v_space * 2);
  padding:0 !important;
  &::before{
    float:left;
    text-indent:0;
    font-size:$icon_size_normal;
    vertical-align:middle;
    width:$icon_size_normal + ($btn_default_v_space * 2);
    line-height:$icon_size_normal + ($btn_default_v_space * 2) !important;
    text-align:center;
    margin:0 0 0 -1px !important;
  }
  &.btn--xs{
    height:$icon_size_xs + ($btn_xs_v_space * 2);
    width:$icon_size_xs + ($btn_xs_v_space * 2);
    line-height:$icon_size_xs + ($btn_xs_v_space * 2);
    &::before{
      font-size:$icon_size_xs;
      height:$icon_size_xs + ($btn_xs_v_space * 2);
      width:$icon_size_xs + ($btn_xs_v_space * 2);
      line-height:$icon_size_xs + ($btn_xs_v_space * 2) !important;
    }
  }
  &.btn--xl{
    height:$icon_size_xl + ($btn_xl_v_space * 2);
    width:$icon_size_xl + ($btn_xl_v_space * 2);
    line-height:$icon_size_xl + ($btn_xl_v_space * 2);
    &::before{
      font-size:$icon_size_xl;
      height:$icon_size_xl + ($btn_xl_v_space * 2);
      width:$icon_size_xl + ($btn_xl_v_space * 2);
      line-height:$icon_size_xl + ($btn_xl_v_space * 2) !important;
    }
  }
  &.btn--xxl{
    height:$icon_size_xxl + ($btn_xxl_v_space * 2);
    width:$icon_size_xxl + ($btn_xxl_v_space * 2);
    line-height:$icon_size_xxl + ($btn_xxl_v_space * 2);
    &::before{
      font-size:$icon_size_xxl;
      height:$icon_size_xxl + ($btn_xxl_v_space * 2);
      width:$icon_size_xxl + ($btn_xxl_v_space * 2);
      line-height:$icon_size_xxl + ($btn_xxl_v_space * 2) !important;
    }
  }
  &.btn--xxxl{
    height:$icon_size_xxxl + ($btn_xxxl_v_space * 2);
    width:$icon_size_xxxl + ($btn_xxxl_v_space * 2);
    line-height:$icon_size_xxxl + ($btn_xxxl_v_space * 2);
    &::before{
      font-size:$icon_size_xxxl;
      height:$icon_size_xxxl + ($btn_xxxl_v_space * 2);
      width:$icon_size_xxxl + ($btn_xxxl_v_space * 2);
      line-height:$icon_size_xxxl + ($btn_xxxl_v_space * 2) !important;
    }
  }
}

/* -- Full Width Buttons */
.btn--block,
.btn-inside--blocked a {
  display:block;
}

/* -- Icons inside buttons - for example <a class="btn ico-zoom-in">Buttontext</a> */
.btn::before{
  display: inline-block;
  font-family: $iconfont;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  margin-right:5px;
}

/* =============================================================================
 *   Icons
 * ========================================================================== */

/* Iconfont */
@font-face {
  font-family: 'iconfont';
  src:url('../fonts/iconfont/iconfont.eot?-cbfawx');
  src:url('../fonts/iconfont/iconfont.eot?#iefix-cbfawx') format('embedded-opentype'),
      url('../fonts/iconfont/iconfont.woff2?-cbfawx') format('woff2'),
      url('../fonts/iconfont/iconfont.woff?-cbfawx') format('woff'),
      url('../fonts/iconfont/iconfont.ttf?-cbfawx') format('truetype'),
      url('../fonts/iconfont/iconfont.svg?-cbfawx#iconfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

// These classes are just for use directly as html class. Dont extend, use the icon mixin instead!
.ico,
.ico-inline{
  @include ico;
}
.ico-seperate{ // Creates an seperate icon out of its own element
  @include ico-replacement;
}
.menu > li > a.ico-inline{
  &::before{
    font-size:16px; // 16px Menu Icons
    font-weight:normal !important;
    vertical-align:middle;
  }
}

/* -- Verfuegbare Icons (see _objects.scss) */
@each $name, $ico in $icon_list {
  .ico-#{$name}::before {
    content: $ico;
  }
}

/* -- Icon Sizes */
.ico--xs{
  &::before{
    font-size:$icon_size_xs;
  }
  &.ico-seperate{
    width:$icon_size_xs;
    height:$icon_size_xs;
    line-height:$icon_size_xs;
  }
}
.ico--normal{
  &::before{
    font-size:$icon_size_normal;
  }
  &.ico-seperate{
    width:$icon_size_normal;
    height:$icon_size_normal;
    line-height:$icon_size_normal;
  }
}
.ico--xl{
  &::before{
    font-size:$icon_size_xl;
  }
  &.ico-seperate{
    width:$icon_size_xl;
    height:$icon_size_xl;
    line-height:$icon_size_xl;
  }
}
.ico--xxl{
  &::before{
    font-size:$icon_size_xxl;
  }
  &.ico-seperate{
    width:$icon_size_xxl;
    height:$icon_size_xxl;
    line-height:$icon_size_xxl;
  }
}
.ico--xxxl{
  &::before{
    font-size:$icon_size_xxxl;
  }
  &.ico-seperate{
    width:$icon_size_xxxl;
    height:$icon_size_xxxl;
    line-height:$icon_size_xxxl;
  }
}

/* Term ID based Icons */
$poiCol_gewerbe:#1d57c0;
$poiCol_wohnen:#b812db;
$poiCol_bildung:#127fdb;
$poiCol_vereine:#db7512;
$poiCol_gesundheit:#c01d1d;
$poiCol_essen_trinken:#8cc01d;
$poiCol_freizeitangebot:#f1cd08;


.ico-tid{
  // POI Categories (old definition - deprecated)
  &--13{
    background-color:$poiCol_gewerbe !important;
    &::before{ @include get-ico-only('city'); } // Gewerbe
  }
  &--14{
    background-color:$poiCol_wohnen !important;
    &::before{ @include get-ico-only('furniture'); } // Wohnen
  }
  &--15{
    &::before{ @include get-ico-only('misc'); } // Sonstiges
  }
  &--18{
    background-color:$poiCol_bildung !important;
    &::before{ @include get-ico-only('mortarboard'); } // Bildung
  }
  &--22{
    background-color:$poiCol_vereine !important;
    &::before{ @include get-ico-only('user-group'); } // Vereine / Gruppe
  }
  &--23{
    background-color:$poiCol_gesundheit !important;
    &::before{ @include get-ico-only('aidkit'); } // Gesundheit
  }
  &--27{
    background-color:$poiCol_essen_trinken !important;
    &::before{ @include get-ico-only('meal'); } // Essen & Trinken
  }
  &--30{
    background-color:$poiCol_freizeitangebot !important;
    &::before{ @include get-ico-only('soccer'); } // Freizeitangebot
  }
}

.poi-typ-ico{
  font-family:$iconfont;
  &::before{
    font-family:inherit;
    display: block;
    line-height: 33px;
    font-size: 15px;
  }
  &--mortarboard{
    &::before{ @include get-ico-only('mortarboard'); } // Bildung
  }
  &--meal{
    &::before{ @include get-ico-only('meal'); } // Bildung
  }
  &--soccer{
    &::before{ @include get-ico-only('soccer'); } // Bildung
  }
  &--aidkit{
    &::before{ @include get-ico-only('aidkit'); } // Bildung
  }
  &--city{
    &::before{ @include get-ico-only('city'); } // Bildung
  }
  &--user-group{
    &::before{ @include get-ico-only('user-group'); } // Bildung
  }
  &--furniture{
    &::before{ @include get-ico-only('furniture'); } // Bildung
  }
  &--misc{
    &::before{ @include get-ico-only('misc'); } // Bildung
  }
}
.ico-reset{
  // Map reset button icon
  font-family:$iconfont;
  &::before{
    font-family:inherit;
    line-height: 21px;
    vertical-align: top;
  }
}

/* =============================================================================
 *   Box Styles
 * ========================================================================== */

/* Default Box Style */
.box-style{
  @include box-style;
}
.box-style--info{
  @include box-style-mod('info');
}

/* Expandable Blocks */
.webks-expandable-container{
  position:relative;
  &.default-hidden:not(.webks-expandable-container--expanded){
    .block-content,
    .webks-expandable-container__content{
      display:none;
    }
  }
  &--expanded{
    .webks-expandable-container__content{
      display:block;
    }
  }
  &__trigger{
    cursor:pointer;
  }
  &__content{
    &:hover{
      z-index:1000 !important; // overlay the full cart flyout
    }
  }
}

/* -- Hide them if js isnt loaded / disabled */
html:not(.js){
  .block--expandable{
    display:none;
  }
}

/* =============================================================================
 *   Slideshows
 * ========================================================================== */


// TODO: OWL is deprecated, we've switched to slick slider (find the code beyond)
// .owl-carousel{
//   overflow:hidden;
//   min-height:5px;
// }
// .owl-theme{
//   .owl-item{
//     overflow:hidden;
//   }
//   .view-image{
//     line-height:0;
//     margin:0;
//     img{
//       max-width:none;
//       width:100%;
//     }
//     a{
//       display:block;
//     }
//   }
//   .owl-nav{
//     // opacity:0;
//     margin:0;
//     > .owl-prev,
//     > .owl-next{
//       position:absolute;
//       top:50%;
//       text-indent:-9000px;
//       height:70px;
//       margin:-35px 0 0;
//       padding:0;
//       width:35px;
//       color:$text-color;
//       background:white;
//       animation-duration: 500ms;
//       animation-fill-mode: both;
//       &::before{
//         font-family:$iconfont;
//         text-indent:0;
//         position:absolute;
//         left:0;
//         line-height:70px;
//         vertical-align:middle;
//         width:35px;
//         height:70px;
//         text-align:left;
//         font-size:18px;
//       }
//       &:hover{
//         color:$highlight_sec;
//         background:$white;
//       }
//     }
//     .owl-prev{
//       left:0;
//       border-radius:0 70px 70px 0;
//       animation-name: fadeOutLeft;
//       &::before{
//         @include get-ico-only('arrow-thin-left');
//       }
//     }
//     .owl-next{
//       right:0;
//       border-radius:70px 0 0 70px;
//       animation-name: fadeOutRight;
//       &::before{
//         @include get-ico-only('arrow-thin-right');
//         text-align:right;
//       }
//     }
//   }
//   &:hover{
//     .owl-nav{
//       // opacity:1.0;
//       .owl-prev{animation-name: fadeInLeft;}
//       .owl-next{animation-name: fadeInRight;}
//     }
//   }
// }

// Slick
.slick{
  overflow:hidden;
  img{
    max-width:none;
    width:100%;
  }
  .slick__arrow{
    // Arrows outer wrapper
    height:70px;
    margin:-35px 0 0 !important;
  }
  .slick-arrow{
    top:0;
    text-indent:-9000px;
    height:70px;
    margin:0 !important;
    padding:0;
    width:35px;
    border:0 none;
    color:$text-color !important;
    background:white !important;
    animation-duration: 500ms;
    animation-fill-mode: both;
    &:hover,
    &:focus{
      color: $highlight !important;
      background: $white !important;
    }
    &::before{
      font-family:$iconfont;
      text-indent:0;
      position:absolute;
      top:0 !important;
      left:0;
      color: currentColor;
      line-height:70px;
      vertical-align:middle;
      width:35px !important;
      height:70px !important;
      opacity:1;
      text-align:center;
      font-size:18px;
    }
    &:hover{
      color:$highlight_sec;
      background:$white;
    }
    &.slick-prev{
      border-radius:0 70px 70px 0;
      animation-name: fadeOutLeft;
      &::before{
        @include get-ico-only('arrow-thin-left');
      }
    }
    &.slick-next{
      border-radius:70px 0 0 70px;
      animation-name: fadeOutRight;
      &::before{
        @include get-ico-only('arrow-thin-right');
      }
    }
  }
  &:focus-within,
  &:hover{
    .slick-arrow.slick-prev{
      animation-name: fadeInLeft;
    }
    .slick-arrow.slick-next{
      animation-name: fadeInRight;
    }
  }
}

/* Slider Overlays */
.slide-overlay{
  color:white;
  overflow:hidden; // clearfix
  max-width:100%;
  flex-shrink:1;
  background: linear-gradient(to bottom, $bluish_grey_dark 0%, $bluish_grey_light 100%);
  @media #{$enough-width}{
    position:absolute;
    left:0;
    width:100%;
  }
  // Animate
  animation-duration: 650ms;
  animation-fill-mode: both;
  &--highlight{
    // unused
  }
  &--highlight-sec{
    background: linear-gradient(to bottom, $highlight_sec 0%, $highlight_sec_light 100%);
  }
  &--bottom{
    bottom:0;
  }
  // Contents
  &__contents{
    padding:$thin_grid;
    max-width:100%;
    flex:1;
    overflow:hidden; // clearfix
  }
  &__title{
    margin:0;
    color:white;
    font-family:$font_first;
    font-size:24px;
    line-height:1.2em;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    @media #{$large-up}{
      font-size:35px;
    }
  }
  &__bottom-line{
    // wraps description and read on into one line
    white-space:nowrap;
    display: flex;
    justify-content: space-between;
  }
  &__description{
    white-space:nowrap;
    overflow:hidden;
    max-width:100%;
    text-overflow:ellipsis;
    margin:0;
    @media #{$enough-width}{
      float:left;
    }
  }
  &__read-on{
    float:right;
    margin-left:$thin_grid;
  }
  a{
    color:white;
  }
  // Event
  &--event{
    display: flex;
  }
  .date-rect{

  }
}
// TODO: Remove owl selector if slick styling finished
.slick__slide.slick-active,
.owl-item.active{
  .slide-overlay{
    // transform:translateY(0);
    animation-name: fadeInUp;
  }
}


/* =============================================================================
 *   Media Objects
 * ========================================================================== */

 // .node.view-mode-teaser_line{
 //   display:flex;
 //   align-items: center;
 //   border:1px solid $bluish_grey_lighter;
 //   padding-right:$thin-grid;
 //   .field-name-field-titelbild{
 //     img{
 //       max-width:none;
 //       height:100%;
 //       width:auto;
 //     }
 //     .image-caption{
 //       display:none;
 //     }
 //   }
 // }
 // .teaser-line__image{
 //   @include media-breakpoint-up(lg){
 //     align-self:stretch;
 //     *{
 //       display:block;
 //       height:100%;
 //     }
 //   }
 // }
 // .teaser-line__content{
 //   @include media-breakpoint-up(lg){
 //     flex-shrink:1;
 //     padding-left:$thin-grid;
 //     padding-right:$thin-grid;
 //   }
 // }
 // .teaser-line__links{
 //   a{
 //     display:block;
 //     white-space: nowrap;
 //     overflow:hidden;
 //     text-overflow: ellipsis;
 //   }
 // }

.media-object{
  @include make-row($thin_grid);
  // equal height
  display:flex;
  &__image{
    @include make-col($thin_grid);
    max-width:35%;
    text-align:center;
    line-height:0;
    a{
      display:block;
      // background-color:$image_background_color;
    }
    @include media-breakpoint-up(lg){
      align-self:stretch;
      *:not(img){
        display:block;
        height:100%;
      }
    }
  }
  &__content{
    @include make-col($thin_grid);
    @include make-col-span(9);
    flex:1;
    > header{
      margin-bottom:1rem;
    }
  }
  &__title{
    margin-top:0;
  }
  &:hover{
    .media-object__title{
      color:$highlight_sec;
      a{
        color:$highlight_sec;
      }
    }
  }
  &--date{
    // Event Media Objects (including date range column at the left)
    margin-left:0;
    margin-right:0;
    border:1px solid $grey_light;
    padding-right:$thin_grid;
    @include media-breakpoint-down(lg){
      flex-wrap: wrap;
    }
    .media-object__date{

    }
    .media-object__image{
      padding-left:0;
      min-width:120px + ($thin_grid / 2);
      max-width:120px + ($thin_grid / 2);
      .image-caption{
        display:none !important;
      }
    }
    .media-object__content{
      width:auto;
      align-self: center;
      .node__title{
        font-size:$font_second_size_px;
        line-height:$line_height_small;
      }
    }
    .media-object__links{
      float:right;
      margin-left:auto; // align right
      align-self: center;
      display:flex;
      align-items:center;
      a{
        display:block;
        white-space: nowrap;
        overflow:hidden;
        text-overflow: ellipsis;
        margin-left:ceil($thin-grid / 2);
      }
    }
  }
}

/* =============================================================================
 *   Misc Objects
 * ========================================================================== */

/* Date */
.date-rect{
  display: flex;
  flex-shrink:1;
  align-items: stretch;
  align-content: stretch;
  justify-content: center;
  @media #{$medium-up}{
    min-width:150px;
    flex-shrink:0;
  }
  > .inner{
    display: flex;
    align-items: center;
    align-content: stretch;
    justify-content: center;
    width:100%;
    padding-left:1rem;
    padding-right:1rem;
    background:$highlight;
    color:$highlight_text_color;
    font-family:$font_second;
  }
  &--date-range{
    .date-wrapper{
      display:inline-block; // fallback - overridden via flexbox
    }
  }
}
.slick{
  .date-rect{
    min-width:0;
    flex-shrink:1;
  }
}

// A single date (inside .date-rect)
.date-wrapper{
  text-align:center;
  line-height:1.1em;
  padding:3px;
  // min-width:66px;
  .dayname{
    font-size:30px;
    padding-bottom:7px;
    + .date-short{
      padding-top:1px;
      border-top:2px solid $highlight_text_color;
    }
  }
  .date-short{
    display:inline-block;
  }
}

/* Cutlines */
.cutline-top{
  @include cutline('top');
}
.cutline-bottom{
  @include cutline('bottom');
}

// Fields - remove nonsense clearfix - display:inline dont need a clearfix.
.field:not(.field-name-body).clearfix {
  &::after{
    display:none;
  }
}

/* Tags (Tagstyle) */
.tagstyle,
.tagstyle ul,
.field-name-field-tags{
  margin:0;
  padding:0;
}
.tagstyle__tag,
.tagstyle li,
.tagstyle ul li,
.tagstyle .field-item,
.field-name-field-tags .field-item{
  list-style:none;
  margin: 0 0.25em 0.25em 0;
  padding:0;
  display:inline-block;
}
.tagstyle__tag a,
.tagstyle li a,
.tagstyle ul li a,
.tagstyle .field-item a,
.field-name-field-tags .field-item a{
  @include btn('ghost', 'xs');
  .ico-seperate{
    &::before{
      font-size:$btn_xs_font_size;
      line-height:1em;
    }
  }
}

/* Subline & Submitted */
.field-name-field-subline,
.subline,
.submitted{
  color: lighten($text_color, 30%);
  font-size: $font_small;
  margin-bottom:0.5em;
  line-height:1.15em;
}
.has-subline{ margin-bottom:.15em; }

/* Lists */
.checklist {
  list-style: none outside none;
  padding-left:5px;
  li{
      margin-bottom: 0.75em;
      padding-left: 33px;
      position: relative;
      &:before {
        border: 2px solid #DFE3E4;
        border-radius: 100% 100% 100% 100%;
        color: #4db848;
        @include get-ico-only('check');
        font-family: $iconfont;
        font-size: 18px;
        left: 0;
        line-height: 18px;
        padding: 2px;
        position: absolute;
        top: -2px;
      }
   }
}

/* Responsive embed videos */
iframe{
  // Minimum fix to prevent crashing the layout
  max-width:100%;
  height:auto;
}
.embed-responsive,
.responsive-iframe-wrapper {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;

  .embed-responsive-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0;
  }
}

// Modifier class for 16:9 aspect ratio
.responsive-iframe-wrapper,
.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

// Modifier class for 4:3 aspect ratio
.embed-responsive-4by3 {
  padding-bottom: 75%;
}

// Maps
.leaflet-pane{
  z-index:1;
}
.pane--custom-directions-map{
  .pane-title{
    margin-top:0;
    font-size:$font_normal;
  }
}
.custom-directions-map{
  overflow:hidden; // clearfix
  margin-bottom:$grid_vertical;
  &__form-wrapper{

  }
  &__form{
    label{
      display:block;
    }
  }
}
// Leaflet marker & popups
.leaflet-interactive{
  .poi-typ-ico{
    position:absolute;
    top:0;
    left:0;
    margin-left: -17px;
    margin-top: -53px;
    width: 33px;
    height: 53px;
    min-width:0;
    color:$white;
    &:hover{
      text-shadow:0 0 2px rgba(255,255,255,.5);
    }
  }
}
.leaflet-popup-content-wrapper{
  border-radius:$border-radius-small;
}
.leaflet-popup-content{
  font-weight:$font_weight_normal;
  font-family:$font_first;
  h2,
  h3,
  h4{
    margin-bottom:.25rem;
  }
  p{
    margin:0 0 .5em;
  }
}

// Syned markers
.leaflet-tagged-marker.synced-content-hover{
  > img{
    // Prevent this ugly default zoom effect
    width: 33px !important;
    height: 53px !important;
    margin-left: -17px !important;
    margin-top: -53px !important;
  }
  > i{
    top:0 !important;
  }
}

// Leaflet scroll protection for mobile devices
.leaflet-view--scroll-protected{
  position:relative;
}
.leaflet-scroll-protection-overlay{
  cursor:pointer;
  position:absolute;
  left:0;
  top:0;
  z-index:3;
  width:100%;
  height:100%;
  padding:10px;
  background:$black_glass_light;
  display:flex;
  align-items:center;
  justify-content:center;
  color:$white;
  text-shadow:1px 1px 1px $black;
}


/* =============================================================================
 *   Tooltips (via Popper.js > https://popper.js.org/documentation.html)
 * ========================================================================== */

$tooltip_color: $highlight;
$tooltip_text_color: $highlight_text_color;
.popper{
  background:$tooltip_color;
  color:$tooltip_text_color;
  padding:$thin_grid;
  width: 150px;
  text-align:center;
  border-radius:$border_radius_small;
  box-shadow:$shadow_small;
  &[x-placement^="top"] {
    margin-bottom: 5px;
    .popper__arrow {
        border-width: 5px 5px 0 5px;
        border-color: $tooltip_color transparent transparent transparent;
        bottom: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
    }
  }
  &[x-placement^="bottom"] {
      margin-top: 5px;
      .popper__arrow {
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent $tooltip_color transparent;
        top: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
  }
  &[x-placement^="right"] {
      margin-left: 5px;
      .popper__arrow {
        border-width: 5px 5px 5px 0;
        border-color: transparent $tooltip_color transparent transparent;
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
  }
  &[x-placement^="left"] {
      margin-right: 5px;
      .popper__arrow {
        border-width: 5px 0 5px 5px;
        border-color: transparent transparent transparent $tooltip_color;
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
  }
  a{
    color:$tooltip_text_color;
  }
  .popper__arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
  }
}

/* =============================================================================
 *   jQuery.UI
 * ========================================================================== */

// #webksde-TF: Testweise entfernt um das multiselect widget sauber stylen zu koennen!


/* =============================================================================
 *   Messages
 * ========================================================================== */

/* Left and right margin are set by the global gutter width */
.message,
div.messages {
  @include message;
}

/* Message Types */
.message--info,
div.info {
  @include message-mod(info);
}
.message--success,
div.status {
  @include message-mod(success);
}
.message--warning,
div.warning {
  @include message-mod(warning);
}
.message--error,
div.error {
  @include message-mod(error);
}

/* -- Other warning & error elements */
tr.warning { color:$message_warning_text; }
tr.error { color:$message_error_text; }

/* Form Errors */
input[type="text"].error,
.form-text.error,
textarea.error,
.error {
  border-color:$message_error_border;
}

/*
 * Unpublished nodes
 */
.node-unpublished p.unpublished,
.comment-unpublished p.unpublished {
  color: pink;
  color: rgba(239, 170, 170, 0.4);
  font-family: Impact,"Arial Narrow",Helvetica,sans-serif;
  font-size: 75px;
  font-weight: bold;
  height: 0;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  overflow: visible;
  text-align: center;
  text-transform: uppercase;
  word-wrap: break-word;
}
